.table--flex {
    font-size: $font-size-md;

    &__header {
        @extend .py-4;

        background-color: $alabaster;
        font-family: $roboto-medium;
        font-size: 1.2rem;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .table--flex__row {
            color: $curious-blue;
        }

        .table--flex__cell {

            &::after {
                @include triangle;

                margin-left: 5px;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }

            &.ordered {
                &--asc {
                    &::after {
                        transform: rotate(-90deg);
                    }
                }

                &--desc {
                    &::after {
                        transform: rotate(90deg);
                    }
                }
            }

            &:nth-child(1){
                width: 20%;
            }

            &:nth-child(2){
                width: 15%;
            }

            &:nth-child(3){
                width: 15%;
            }

            &:nth-child(4){
                width: 15%;
            }

            &:nth-child(5){
                width: 15%;
            }

            &:nth-child(6){
                width: 20%;
            }
        }
    }

    &__body {
        .table--flex__cell {
            &:nth-child(2){
                width: 20%;
            }
        }
    }

    &__row {
        align-items: center;
        border-top: 1px solid $alto;
        color: $dove-gray;
        flex-direction: row;
        font-weight: 300;
        position: relative;

        &:first-child {
            border: none;
        }

        &.shared {
            font-family: $roboto-medium;
            font-weight: 500;
        }
    }

    &__cell {
        padding: 1rem;

        &.table--flex__cell--mobile-container {
            @extend .p-0;

            $parentWidth: 80;
            justify-content: center;
            display: flex;
            width: $parentWidth * 1%;

            .table--flex__sub-cell {
                flex-direction: row;
                padding: 1rem;
                $width: (100 / $parentWidth * 15) * 1%;
                width: $width;

                &:nth-child(1) {
                    $width: (100 / $parentWidth * 20) * 1%;
                    width: $width;
                }

                @include media-breakpoint-down(md) {
                    width: 50%;

                    &:nth-child(1) {
                        width: 50%;
                    }

                    &.table__remaining-time {
                        padding-top: 0;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;
                flex-grow: 9;
                position: relative;

                .table--flex__sub-cell {

                    &:nth-child(4) {
                        left: 50%;
                        margin: auto;
                        order: 4;
                        position: absolute;
                    }

                    &:nth-child(5) {
                        order: 3;
                    }
                }
            }
        }
    }

    .table {
        &__school-class {
            word-break: break-all;

            span {
                display: block;
                font-size: 1.2rem;
            }
        }

        &__actions {

            @include media-breakpoint-down(md) {
                display: none;
            }

            &--mobile {
                display: none;
                text-align: right;

                @include media-breakpoint-down(md) {
                    display: block;
                }

                .popover--collapse {
                    background-color: $golden-tainoi;
                    position: absolute;
                    right: 16px;
                    z-index: 10;

                    &::after {
                        border-color: transparent transparent transparent $golden-tainoi;
                        border-style: solid;
                        border-width: 10px 0 10px 6px;
                        content: '';
                        cursor: pointer;
                        display: inline-block;
                        height: 0;
                        vertical-align: middle;
                        width: 0;
                        position: absolute;
                        right: 7px;
                        top: -13px;
                        transform: rotate(-90deg);
                    }

                    button,
                    a {
                        border-left: 1px solid $manatee;

                        &:first-child {
                            border: none;
                        }
                    }

                    button {
                        background-color: transparent;
                    }

                    svg {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                .point {
                    background-color: $dove-gray;
                    border-radius: 50%;
                    display: inline-block;
                    height: 5px;
                    width: 5px;
                }
            }

            button:not(.btn-action) {
                border: none;
                cursor: pointer;
                outline: none;
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }

            a,
            button {
                vertical-align: middle;
            }

            a:not(.btn-action),
            button:not(.btn-action) {
                @extend .px-2;

                background: none;
                color: $dove-gray;
                display: inline-block;
                font-size: $font-size-xs;
                text-align: center;
                text-transform: uppercase;
                transition: all .3s;

                &:hover {
                    transform: translateY(-2px);
                }
            }

            svg {
                display: block;
                height: 40px;
                margin: auto;
                max-width: 40px;
            }
        }
    }
}
