.articles-section {
  margin-bottom: 100px;
  margin-top: 100px;
}

.articles-section__header {
  padding: 5rem 0 10rem;

  h2 {
    color: $white;
    font-size: $font-size-xxl;
    margin-bottom: 0;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

.articles-section__title__link,
.articles-section__footer__link {
  border: 2px solid $white;
  border-radius: 1rem;
  color: $white;
  padding: 2rem;
  text-transform: uppercase;
  transition: all .3s;

  &:hover {
    color: $white;
    text-decoration: none;
    transform: translateY(-.5rem);
  }
}

.articles-section__title__link {
  @include btn--bordered($white);
}

.articles-section__footer__link {
  @include btn--bordered($curious-blue);
}

.articles-section__list {
  position: relative;
  top: -5rem;
  &__folders {
      top: -10rem;
      margin-bottom: -10rem;
      margin-top: 3rem;
      .articles-section__container {
          margin-bottom: 3rem;
      }
      @include media-breakpoint-up(md) {
          top: 0;
          margin-bottom: 0;
          margin-top: 13rem;
          & > div:nth-child(2n+ 0) {
              margin-top: -10rem;
          }
      }
  }

  @include media-breakpoint-down(md) {
    article {
      margin-bottom: 3rem;
    }
  }

  article {
    height: 450px;
    background-size: cover;
    background-position: center;
    padding: 210px 10% 2rem 10%;
    background-color: $athens;
  }

  &--2-col {
    .articles-section__container {
      padding-top: 300px;
      .articles-section__element {
        position: relative;
        width: 80%;
        margin: 0 auto 0;
        z-index: 1;
      }
      h3 {
        font-size: $font-size-base;
      }
      .picture {
        position: absolute;
        height: 340px;
        left: 0;
        right: 0;
        top: 0;
        z-index: 0;
        background-size: cover;
        background-position: center;
      }
    }

    @include media-breakpoint-down(md) {
      article {
        margin-bottom: 30%;
      }
    }
  }
}

.articles-section__element {
  background: $white;
  padding: 2rem;

  time,
  .time {
    font-family: $roboto-medium;
  }

  header {
    font-size: 1.2rem;

    h3 {
      font-size: $font-size-md;
    }
  }

  section {
    font-size: 1.2rem;
  }
  .body {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; // number of lines to show
    // Fallback for non-webkit browser
    line-height: 1.7rem;
    max-height: 9rem; // = line-height * number of lines to show (+ une petite marge)
  }

  footer {
    a {
      font-size: $font-size-md;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
}

.articles-section__category {
  position: absolute;
  top: 0;
  left: 15px;
  background-color: $white;
  color: $violet-red;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-size: $font-size-md;
  z-index: 10;
}

.folders {
  .articles-section__category {
    left: 0;
  }
}

