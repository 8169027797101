html {
	font-family: $font-family-base;
	font-size: 62.5%;
}
body {
	background-color: $white;
    &.background-gray {
        background-color: $alabaster;
    }
    &.nfm { // No Footer Margin
        .footer {
            margin-top: 0;
        }
    }
}
