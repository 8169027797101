.tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: $golden-tainoi;
    color: $tundora;
    font-size: $font-size-md;
    line-height: normal;
    padding: 2.5rem;
    text-align: center;
    z-index: 100;
    &__open {
        display: none;
    }
    svg {
        fill: $tundora;
        width: 50px;
        height: 50px;
        margin-bottom: 1rem;
    }
}

.tips {
    position: absolute;
    top: 20vh;
    right: -25rem;
    width: 25rem;
    transition: transform .5s;
    &__inner {
        position: relative;
    }
    &__open {
        display: block;
        position: absolute;
        left: -10rem;
        top: 0;
        width: 7rem;
        height: 7rem;
        background-color: $golden-tainoi;
        padding: 1rem;
        border-radius: 50%;
        cursor: pointer;
        animation-delay: 1s;
        animation-duration: .5s;
        svg {
            width: 100%;
            height: 100%;
        }
        &--inactive,
        &--active {
            height: 100%;
            svg {
                fill: $tundora;
            }
        }
        &--active {
            display: none;
            padding: 1rem;
        }
    }
    &.active {
        transform: translateX(-25rem);
    }
}

.tips.second {
    right: -32rem;
}
.tips.top8 {
    top: 8vh;
}

.tips.top50 {
    top: 50vh;
}

.tips.top54 {
    top: 54vh;
}

.tips.top40 {
    top:40rem;
}

.tips.top50 {
    top:50rem;
}

.tips.index200 {
    z-index:200;
}

.tips.index300 {
    z-index:300;
}

.tips.top60 {
    top:60rem;
}

.tips.lessright {
    right:-24rem;
}

section.isrelative,
div.isrelative {
    position:relative;
}
