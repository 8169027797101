.stats-title {
    h1 {
        font-size: 2.4rem;
        text-transform: uppercase;
    }
}

.stats-filter {
    padding-bottom: 4rem;

    &__content {
        background-color: $white;
        border: 1px solid $mercury;
        padding: 2rem;
        text-transform: uppercase;
        &__title {
            color: $manatee;
            font-size: 1.2rem;
        }
        .form-wrapper {
            align-items: center;
        }
        select {
            max-width: 100%;
            border: solid 1px;
            height: 30px;
            padding: 0;
        }
    }
}

.stats-filter__content,
.stats-synthesis__header {
    background-color: $white;
    border: 1px solid $mercury;
    border-radius: .5rem;
    padding: 2rem;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    h2 {
        margin-bottom: 1rem;

        span {
            color: $curious-blue;
        }
    }

    &__title {
        color: $manatee;
        font-size: 1.2rem;
    }

    &__details {
        background-color: $alabaster;
        color: $manatee;
        font-size: $font-size-md;
        padding: 1rem;
        text-transform: none;
    }
}

.stats-synthesis {
    background-color: $white;
    padding-top: 4rem;

    &__content {
        margin-top: 5rem;
        padding-bottom: 3rem;

        .nav-tabs {
            border: 0;
            justify-content: center;

            .nav-item {
                padding: 0;
                position: relative;
                text-align: center;

                &:first-child {
                    left: 15px;
                }

                &:last-child {
                    right: 15px;
                }
            }

            .nav-link {
                border: 1.5px solid $curious-blue;
                border-radius: 20px;
                font-size: $font-size-lg;
                padding: .5rem 1.5rem;

                &.active {
                    background-color: $curious-blue;
                    color: $white;
                    z-index: 10;
                }
            }
        }
    }
}

.graph-legend {
    padding: 3rem 2rem;
    text-align: center;

    @include media-breakpoint-down(sm) {
        text-align: left;
    }

    p {
        display: inline-block;
        font-size: $font-size-md;
        padding: 1rem;

        &::before {
            $dimension: 2rem;

            border-radius: 50%;
            content: '';
            display: inline-block;
            height: $dimension;
            vertical-align: middle;
            width: $dimension;
        }
    }

    &__student-do {
        &::before {
            background-color: $orange;
        }
    }

    &__student-think {
        &::before {
            background-color: $yellow;
        }
    }
}

.slick-dots {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 2rem 0;

    li {
        border: solid 1px $curious-blue;
        border-radius: 50%;
        height: 10px;
        margin: 0 .5rem;
        width: 10px;
        &.slick-active {
            background-color: $curious-blue;
        }
    }

    button {
        background: transparent;
        border: none;
        outline: none;
        text-indent: 9999px;
    }
}

.question-stats {

    &-container {
        background-color: $white;
        border: 1px solid $gallery;
        box-shadow: 0 5px 4px 0 rgba(0, 0, 0, .09);
        padding: 2rem;
        text-align: center;
    }

    &__title {
        color: $curious-blue;
        font-size: $font-size-md;
        text-transform: uppercase;

        &::after {
            background-color: $curious-blue;
            content: '';
            display: block;
            height: 1px;
            margin: 1rem auto;
            width: 8%;
        }
    }

    &__label {
        font-size: $font-size-base;
    }
}

.stats-themes {

    &__title {
        margin: 3rem 0;
        text-transform: uppercase;
    }

    &__nav-tabs {
        align-items: center;
        border-bottom: none;
        justify-content: center;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .stats-themes__nav-item {
            padding: 0;
            text-align: center;
            width: 20%;

            .stats-themes__nav-link {
                border: 1px solid $gallery;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                color: $mulled-wine;
                height: 100%;
                position: relative;

                &.active {
                    &::before {
                        background-color: $golden-tainoi;
                        border-radius: 4px;
                        content: '';
                        display: inline-block;
                        height: 5px;
                        left: -1px;
                        position: absolute;
                        top: -5px;
                        width: calc(100% + 2px);
                    }
                }
            }
        }
    }

    &__dropdown {
        background-color: $white;
        display: none;
        margin-bottom: 1rem;

        .btn {
            background: transparent;
            border: none;
            border-radius: 0;
            color: $curious-blue;
            text-align: left;
            width: 100%;

            &::after {
                position: absolute;
                right: 2rem;
                top: 50%;
            }
        }

        .dropdown-menu {
            border-radius: 0;
            width: 100%;

            &::before {
                display: none;
            }

            .stats-themes__dropdown-item {
                color: $curious-blue;
            }
        }

        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}

.select2-container--default {
    .select2-results__option[aria-selected="true"]{
        background-color: $curious-blue;
        color: $white;
    }
    .select2-selection--single{
        color: $curious-blue;
    }
    .select2-selection--multiple {
        .select2-selection__rendered {
            color: $white;
            .select2-selection__clear{
                margin-right:5px;
                color: $curious-blue;
            }
        }
        .select2-selection__choice{
            background: $curious-blue;
            color: $white;
            font-size:12px ;
            border: 1px solid $curious-blue;
            .select2-selection__choice__remove{
                color: $white;
            }
        }
    }
}
.select2-container {
    .select2-search--inline{
        display: none;
    }
}

section.stats-synthesis > div.row.align-items-end {
    margin:auto;
    padding-left:1rem;
}
