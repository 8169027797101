.panel {
    margin-top: 3rem;

    &--login,
    &--access {
        background-color: $white;
        position: relative;
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .37);
        h1 {
            text-transform: uppercase;
            font-size: $font-size-xl;
        }
        .form-group {
            > a {
                text-transform: uppercase;
                font-size: $font-size-md;
            }
            input[type=checkbox] + label {
                width: calc(100% - 16px - 2rem);
            }
        }
        .btn-outline--secondary {
            color: $curious-blue;
            border-color: $curious-blue;
        }
    }
    &--student {
        @include media-breakpoint-down(sm) {
            margin-top: 10rem;
        }
    }
    &__chapo {
        color: $dove-gray;
        font-size: $font-size-md;
        margin-bottom: 2rem;
    }
    &-title {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    &-illu {
        width: 75px;
        height: 75px;
        position: absolute;
        top: -35px;
        right: -35px;
        background-color: $white;
        border-radius: 50%;
        padding: 15px;
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .37);
        z-index: 10;
        &:after {
            content: '';
            position: absolute;
            top: 20px;
            right: 20px;
            width: 55px;
            height: 55px;
            background-color: $white;
            z-index: 20;
            transform: rotate(45deg);
        }
        svg {
            fill: $curious-blue;
            width: 45px;
            height: 45px;
            z-index: 30;
            position: absolute;
            top: 20%;
            left: 20%;
        }
        @include media-breakpoint-down(sm) {
            left: calc(50% - 75px / 2);
            top: -100px;

            &::after {
                background: none;
            }
        }
    }
}
