.pagination {
  justify-content: flex-end;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  .page-item {

    &--empty {
      border: .5px solid $gallery;
      padding: 0 .5rem;
    }

    &--first {
      margin-right: 1rem;
    }

    &--last {
      margin-left: 1rem;
    }

    .page-link {
      border-color: $gallery;
      border-radius: 0;
      border-width: .5px;
      font-size: $font-size-md;
      margin-left: 0;
      padding: .5rem 1rem;

      &:hover {
        color: $curious-blue;
      }

      &__next {
        @include triangle($curious-blue, 4px);
      }

      &__previous {
        @include triangle($curious-blue, 4px);

        transform: rotate(180deg);
      }

      &__next,
      &__previous {
        &.disabled {
          border-color: transparent transparent transparent $dark-gray;
        }
      }
    }

    &--active {
      .page-link {
        border-color: $curious-blue;
        color: $curious-blue;
      }
    }
  }
}