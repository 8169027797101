.admin_questionnaire_questions-question-actions {
  justify-content: center;
}

.form--questionnaire {
    .a2lix_translationsLocales {
        display: none;
    }
}

.survey-form .a2lix_translationsLocales {
    display: none;
}

.language-switcher {
    &.active {
        background-color: $mulled-wine;
        color: $white;
    }
}
