.survey-intro {
    .bg-polar {
        background-color: $polar;
        padding: 5rem 0 10rem 0;
        @include media-breakpoint-down(sm) {
            padding-bottom: 190px;
        }
    }
    h1 {
        text-transform: uppercase;
        font-size: 2.4rem;
        color: $dove-gray;
    }
    .infos {
        background-color: $golden-tainoi;
        margin-top: -45px;
        position: relative;
        box-shadow: 0 2px 4px 0 rgba(165, 165, 165, .5);
        &__text {
            color: $tundora;
            font-style: italic;
            font-size: 1.6rem;
            padding-right: 130px;
            @include media-breakpoint-down(sm) {
                padding-right: 0;
            }
            &_icon {
                border-radius: 100%;
                width: 55px;
                height: 55px;
                background-color: $tundora;
                flex-shrink: 0;
                svg {
                    fill: $golden-tainoi;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &__time {
            position: absolute;
            right: -20px;
            top: calc(50% - 75px);
            width: 150px;
            height: 150px;
            background-color: $white;
            padding: 2rem;
            border: solid 1px $mercury;
            border-radius: 100%;
            color: $mulled-wine;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 300;
            p {
                &:first-child {
                    font-size: 1.8rem;
                    color: $dove-gray;
                }
            }
            svg {
                width: 50px;
                height: 50px;
                fill: $golden-tainoi;
            }
            @include media-breakpoint-down(sm) {
                top: -135px;
                left: calc(50% - 75px);
            }
        }
    }
    .form-wrapper {
        p {
            font-size: 1.6rem;
            color: $dusty-gray;
        }
        form {
            label {
                color: $curious-blue;
                text-transform: uppercase;
                font-weight: 600;
                &.form-check-label {
                    text-transform: none;
                    color: $manatee;
                    font-weight: normal;
                }
            }
            select {
                border: solid 1px;
                height: 30px;
                padding: 0;
            }
        }
    }
}
