.s-institutional-content-intro {
    background-image: linear-gradient(rgba(255, 206, 82, .75),rgba(255, 206, 82, .75)),
        url('../images/bg-pedagogical-intro.jpg');
    background-position: center;
    background-size: cover;
    padding: 3rem 0 7rem 0;
    &-items {
        margin-top: -7rem;
    }
}

.institutional-content {
    &.news,
    &.tool {
        .institutional-content__img {
            @extend .w-100;
            padding: 0 15px;
            img {
                @extend .w-100;
            }
        }
    }
    &.sponsor {
        .s-institutional-content-intro {
            background-image: linear-gradient(rgba(255, 206, 82, .75), rgba(255, 206, 82, .75)),
                url('../images/bg-sponsor-intro.jpg');
            padding-bottom: 550px;
        }
        .s-institutional-content-content {
            margin-top: -550px;
        }
        .institutional-content {
            &__img {
                margin-bottom: 5rem;
            }
            &__inner {
                transform: none;
            }
            &__wrapper {
                margin-bottom: 5rem;
            }
        }
        .institutional-content__inner {
            h1 {
                @extend .mb-5;
                text-align: center;
            }
        }
        .institutional-content__wrapper {
            border: none;
        }
    }
    &.folders {
        .s-institutional-content-intro {
            background-image: linear-gradient(rgba(81,74,99, .7), rgba(81,74,99, .7)),
                url('../images/bg-files-intro.jpg');
            @include media-breakpoint-up(md) {
                height: 150px;
            }
            h1 {
                color: $white;
            }
        }
        .s-institutional-content {
            &-intro {
                padding-bottom: 0;
                @include media-breakpoint-down(md) {
                    padding-bottom: 6.5rem;
                }
            }
            &-content {
                margin-top: 50px;
            }
        }
    }
    &.news {
        .s-institutional-content-intro {
            background-image: linear-gradient(rgba(245, 47, 130, .75), rgba(245, 47, 130, .75)),
                url('../images/bg-news-intro.jpg');
            height: 150px;
            h1 {
                color: $white;
            }
        }
        .s-institutional-content {
            &-intro {
                padding-bottom: 0;
            }
            &-content {
                margin-top: 50px;
            }
        }
        .institutional-content {
            &__inner {
                transform: translateY(-10rem);
                background-color: $white;
                h2 {
                    padding: 2rem 2rem 0 2rem;
                }
            }
            &__date {
                padding: 0 2rem;
                margin: 0;
            }
            &__category {
                text-transform: uppercase;
                color: $violet-red;
                font-size: $font-size-md;
                padding: 0 2rem 2rem;
                margin-bottom: 2rem;
            }
        }
        .s-institutional-content-recos {
            background-color: rgba(245, 47, 130, .2);
        }
        .articles-section__list {
            top: 0;
        }
    }
    &.folder {
        h2 {
            font-size: $font-size-xxxl;
            margin-bottom: 0;
        }
        .s-institutional-content-intro {
            background-image: linear-gradient(rgba(81, 74, 99, .75), rgba(81, 74, 99, .75)),
                url('../images/bg-files-intro.jpg');
            height: 150px;
            h1 {
                color: $white;
            }
        }
        .s-institutional-content {
            &-intro {
                padding-bottom: 0;
            }
            &-content {
                margin-top: 50px;

                .s-institutional-content-share {
                    .share-item {
                        width: 30px;
                        height: 30px;
                        padding: .5rem;
                        a {
                            width: 100%;
                            height: 100%;
                        }
                        svg {
                            vertical-align: initial;
                        }
                    }
                }
            }
        }
        .institutional-content {
            &__wrapper {
                border: none;
                @extend .p-0;
            }
            &__inner {
                transform: none;
            }
            &__body {
                h3 {
                    @extend .py-5;
                    @extend .mt-5;
                    position: relative;
                    border-top: solid 1px $gallery;
                    &:first-child {
                        border-top: none;
                        @extend .py-0;
                        @extend .mt-0;
                    }
                }
            }
            &__category {
                text-transform: uppercase;
                color: $violet-red;
                font-size: $font-size-md;
            }
        }
        .s-institutional-content-recos {
            background-color: $alto;
            .articles-section {
                &__list {
                    top: 0;
                }
                &__element {
                    background-color: $alto;
                }
                &__category {
                    left: 0;
                }
            }
        }
    }
    .s-institutional-content {
        &-intro {
            padding-bottom: 200px;
        }
        &-content {
            margin-top: -200px;
        }
        &-sources {
            @extend .col-9;
            padding-top: 3rem;

            &::before {
                width: 100%;
            }
        }
    }
    .institutional-content {
        &__wrapper {
            border: solid 1px $gallery;
            border-top: none;
            background-color: $white;
        }
        &__inner {
            transform: translateY(-5rem);
        }
        &__summary {
            background-color: $mercury;
            position: -webkit-sticky;
            position: sticky;
            margin-top: 2.5rem;
            height: 100vh;
            overflow-y: auto;

            ul {
                list-style: none;
                padding: 0;
                li {
                    @extend .p-4;
                    font-size: $font-size-base;
                    line-height: 1.5;
                    font-style: italic;
                    border-bottom: solid 1px $alto;
                    cursor: pointer;
                    transition: all .3s ease-in-out;
                    &:hover {
                        opacity: .5;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                    &.active {
                        font-weight: 600;
                    }
                }
            }
        }
        &__category {
            display: inline-block;
            margin-top: -50px;
            background-color: $white;
            padding: 1rem;
            svg {
                width: 35px;
                height: 35px;
            }
            p {
                font-size: $font-size-lg;
                display: inline-block;
                margin: 0;
            }
        }
        &__date {
            font-size: $font-size-md;
            color: $dusty-gray;
        }
        &__links,
        &__documents {
            list-style: none;
            padding: 2rem 0 0 0;
            li {
                display: inline-block;
                margin-right: 5rem;
            }
        }
        &__links {
            a {
                color: $curious-blue;
                text-decoration: underline;
                transition: transform .3s ease-in-out;
                &:hover {
                    color: $curious-blue;
                    transform: translateY(-.5rem);
                }
            }
        }
        &__documents {
            a {
                color: $curious-blue;
                border-color: $curious-blue;
                svg {
                    fill: $curious-blue;
                    width: 3rem;
                    height: 3rem;
                    margin-right: 2rem;
                }
            }
        }
    }
}

.s-institutional-content-sources {
    @extend .col-12;
    font-size: $font-size-lg;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 3px;
        background-color: $gallery;
    }

    p {
        text-transform: capitalize;
        padding-top: 2rem;
    }
}

.s-institutional-content-recos {
    background-color: rgba(255, 206, 82, .2);
    h2 {
        font-size: $font-size-xxl;
    }
    .btn {
        color: $curious-blue;
        border-color: $curious-blue;
    }
}
.s-institutional-content-share {
    .share-item  {
        width: 50px;
        height: 50px;
        background-color: $facebook;
        border-radius: 50%;
        padding: 1rem;
        margin: 0 1rem;
        transition: transform .3s ease-in-out;
        svg {
            width: 100%;
            height: 100%;
            fill: $white;
        }
        &.twitter {
            background-color: $twitter;
        }
        &.linkedin {
            background-color: $linkedin;
        }
        &.mail {
            background-color: $violet-red;
        }
        &:hover {
            transform: translateY(-.5rem);
        }
    }
}
