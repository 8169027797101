.pedagogical-tools {
    width: 100vw;
    overflow-x: hidden;

}
.s-pedagogical-intro-items {
    margin-top: -7rem;
}
.s-pedagogical-category {
    background-color: rgba(255, 206, 82, .1);
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, .09);
    padding-bottom: 10rem;
}
.pedagogical {
    &__category {
        &__number {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            background-color: $white;
            color: $curious-blue;
            font-size: $font-size-xxxxl;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .5);
            margin: 0 auto;
            transition: transform .3s ease-in-out,
                background-color .3s ease-in-out,
                color .3s ease-in-out;
            will-change: transform, background-color, color;
        }
        &__title {
            font-size: $font-size-base;
            font-weight: 600;
            color: $mulled-wine;
            text-transform: uppercase;
            transition: transform .3s ease-in-out;
        }
        .active {
            .pedagogical__category__number {
                background-color: $curious-blue;
                color: $white;
            }
        }
        @include media-breakpoint-down(md) {
            .pedagogical__category__number {
                background-color: $curious-blue;
                color: $white;
            }
        }
    }
    .slick-slide {
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    .slick-list {
        padding-top: 5px;
    }
    &__items {
        min-height: 550px;
    }
    &__item {
        position: relative;
        margin-bottom: 3rem;
        overflow: hidden;
        background-color: $white;
        &__img {
            height: 25rem;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $white;
            position: relative;
            a {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 10;
                background-color: $white;
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }
            &:hover {
                a {
                    opacity: .5;
                }
            }
        }
        &__category {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $white;
            color: $mulled-wine;
            font-size: $font-size-md;
            padding: 1rem 1.5rem;
            z-index: 20;
            svg {
                width: 25px;
                height: 25px;
            }
            p {
                display: inline-block;
                margin: 0 0 0 .5rem;
            }
        }
        &__content {
            padding: 2.5rem;
            border: solid 1px $gallery;
            color: $mulled-wine;
            min-height: 30rem;
            h2 {
                font-weight: 600;
                font-size: $font-size-base;
            }
            p {
                font-size: $font-size-md;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 7; // number of lines to show
                // Fallback for non-webkit browser
                line-height: 2.1rem;
                max-height: 14.5rem; // = line-height * number of lines to show (+ une petite marge)
            }
            a {
                text-transform: uppercase;
                text-decoration: underline;
                font-size: $font-size-base;
                position: relative;
                will-change: opacity;
                display: block;
                margin-top: 1.5rem;
                text-overflow: ellipsis;
                transition: transform .3s ease-in-out,
                    opacity .2s ease-in-out;
                &:hover {
                    color: $curious-blue;
                    opacity: .7;
                    transform: translateY(-.5rem);
                }
            }
        }
    }
    .btn-outline-secondary {
        @include btn--bordered($curious-blue);
        cursor: pointer;
    }
    &--category {
        &[data-type='category'] {
            box-shadow: 0 5px 4px 0 rgba(0, 0, 0, .1);
        }
        .pedagogical {
            &--category {
                background-color: $white;
                margin: 0 -15px;
                padding: 3rem;
            }
            &__category {
                &__item {
                    @include make-col(2, 10);
                    background-color: $alabaster;
                    border: solid 1px $athens;
                    height: 55px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: -5px;
                        left: -1px;
                        width: calc(100% + 2px);
                        height: 5px;
                        background-color: $golden-tainoi;
                        border-radius: 4px 4px 0 0;
                        opacity: 0;
                        transition: opacity .3s ease-in-out;
                    }
                    &.active {
                        background-color: $white;
                        &:before {
                            opacity: 1;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        background-color: $white;
                        &:before {
                            opacity: 1;
                        }
                    }
                }
                svg {
                    width: 25px;
                    height: 25px;
                }
                p {
                    text-transform: none;
                    font-size: $font-size-md;
                    font-weight: normal;
                }
            }
            &__item__category {
                @extend .d-none;
            }
        }

    }
    &__tab {
        cursor: pointer;
        transition: transform .3s ease-in-out;
        &:hover {
            &:not(.active) {
                transform: translateY(-.5rem);
                @include media-breakpoint-down(md) {
                    transform: none;
                }
            }
        }
    }
    .slick-prev,
    .slick-next {
        @extend .d-none;
    }
    .slick-dots li {
        border-color: $mulled-wine;
        &.slick-active {
            border-color: $curious-blue;
        }
    }
}
