.admin {
    background-color: rgba(21, 150, 212, .2);
    %panel {
        @extend .rounded;

        background-color: $white;
        margin: 3rem auto;
    }

    .panel {
        &--aside {
            @extend %panel;
            padding: 2rem;
            svg {
                width: 25px;
                height: 25px;
                margin-right: 1rem;
            }
            ul {
                list-style: none;
                padding: 0;
                li {
                    padding-bottom: 1rem;
                    margin-bottom: 1rem;
                    border-bottom: solid 1px rgba(21, 150, 212, .2);
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        &--main {
            @extend %panel;
            padding: 2rem 0;
        }
    }
    .form-control {
        background-color: $white;
        border: 1px solid $dark-gray;
    }
    .form-group {
        img {
            @extend .img-fluid;
        }
    }
    .field-error {
        border: 1px solid $punch;
    }

    .pane-error {
        color: $punch;
    }

    .custom-file {
        display: flex;
        overflow: hidden;

        label {
            display: none;
        }

        input {
            max-width: 100%;
            order: 1;
        }
    }

    .answer-picture {
        max-width: 100%;
    }

    .collection {
        &--questions {
            &__question {
                background-color: $dark-gray;
                padding: 1rem;
            }
        }

        &--ad {
            border: 2px solid green;

            &__answer {
                background-color: #d3ffe6;
                padding: 1rem;
            }
        }

        &--at {
            border: 2px solid orange;

            &__answer {
                background-color: #ffead3;
                padding: 1rem;
            }
        }
    }
    h1 {
        text-transform: uppercase;
        font-size: $font-size-lg;
        margin: 0;
    }
    header {
        background-color: $white;
        height: 100px;
        .brand {
            height: 70px;
            svg {
                height: 70px;
            }
        }
    }
    .btn-alert {
        background-color: $violet-red;
        color: $white;
    }

}

.admin-questionnaire {
    &__section {
        background-color: #f7f7f7;
        box-shadow: 0 2px 4px 0 rgba(122, 122, 122, .5);
        margin: 3rem auto;
        padding: 2rem;
    }
}

.collection--questions {
    & > fieldset {
        background-color: $white;
        padding: 2rem;
    }
}

.question-item {
    &__label {
        color: $mulled-wine;

        &.collapsed {
            svg {
                transform: rotate(90deg);
            }
        }

        svg {
            @extend .float-right;

            height: 30px;
            transform: rotate(-90deg);
            transition: all .3s;
            width: 60px;
        }
    }
}

.admin_questionnaire_questions-question-actions {
    order: 2;

    & > button {
        margin-right: 2rem;
    }
}

.admin_questionnaire_questions-question-down,
.admin_questionnaire_questions-question-up {
    display: none;
}

.admin-questionnaire__submit {
    flex-grow: 1;
    order: 3;
    text-align: right;
}

.admin-question--do__title,
.admin-question--think__title {
    color: $shamrock;
    display: none;
    text-align: center;
}

.questions__accordion {
    .admin-question--do__title,
    .admin-question--think__title {
        display: block;
    }
}
