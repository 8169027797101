.dashboard {
  &__header {
    @extend .py-5;

    &__btn {
      @extend .d-inline-block;
      @extend .mb-4;

      @include btn--bordered($curious-blue);

      background-color: transparent;
      width: 100%;

      @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
        min-width: 50%;
        width: auto;
      }

    }

    h2 {
      font-size: 1.8rem;
      text-transform: uppercase;

      span {
        @extend .d-block;

        text-transform: none;
      }
    }
  }

  h1 {
    font-size: $font-size-xl;
    text-transform: uppercase;

    &.archives {
      margin-left: -13px;
      margin-right: -13px;
      span {
        background-color: $white;
        padding: 10px;
        margin-left: -10px;
        margin-right: -10px;
        & a:first-child {
          margin-right: 3px;
        }
        & a:last-child {
          margin-left: 3px;
        }
        svg {
          vertical-align: top;
          width: 8px;
          height: 18px;
          path {
            fill: $link-color;
          }
        }
      }
    }

    span {
      @extend .d-block;

      font-size: $font-size-base;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  &__content {
    @extend .py-5;

    background-color: $white;

    &__select-all {
      @include media-breakpoint-down(md) {
        font: inherit;
        font-size: $font-size-base;
        color: inherit;
        text-shadow: inherit;
        background-color: inherit;
        border: inherit;
      }
    }
  }

  .cumulate-stats__container {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

}

