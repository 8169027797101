/***** MIXINS *****/
@mixin triangle($color: $curious-blue, $size: 6px) {
  border-color: transparent transparent transparent $color;
  border-style: solid;
  border-width: $size 0 $size ($size / 6 * 11);
  content: '';
  cursor: pointer;
  display: inline-block;
  height: 0;
  vertical-align: middle;
  width: 0;
}
/***** MIXINS *****/