@mixin dropdown {
    background-color: $white;
    border: 1px solid $gallery;
    border-radius: $border-radius 0 $border-radius $border-radius;
    left: auto;
    margin-top: 0;
    min-width: 40%;
    padding: 0;
    right: 0;
    top: 90px;

    [dir='rtl'] & {
        border-radius: 0 $border-radius $border-radius $border-radius;
        left: 0;
        right: auto;
    }
}

@mixin dropdown-arrow {
    background-color: $white;
    border-radius: 0 0 0 8px;
    box-shadow: -1px 1px 1px 0 rgb(234, 234, 234);
    content: '';
    height: 2em;
    margin-right: -1px; // border-with of the parent
    position: absolute;
    right: -2 * 1em; // Twice the width of the object
    transform: rotate(135deg);
    transform-origin: 0 0;
    transition: all 1s;
    width: 2em;
    z-index: -10;

    [dir='rtl'] & {
        margin-left: -1px; // border-with of the parent
        margin-right: 0;
        right: auto;
        left: 1.414 * 2em;
    }
}

@mixin dropdown-item {
    border-bottom: 1px solid $gallery;
    color: $mulled-wine;
    font-size: 1.4rem;
    font-family: $roboto-medium;
    padding: 0;
    text-transform: uppercase;
    transition: all .3s;
    width: 100%;

    &:hover {
        background-color: transparent;
        color: $dove-gray;
        transform: translateY(-2px);
    }

    &.active {
        &:first-child {
            border-top-left-radius: $border-radius;
        }

        [dir='rtl'] & {
            &:first-child {
                border-top-left-radius: 0;
                border-top-right-radius: $border-radius;
            }
        }

        &:last-child {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

// Override BS styles
.dropdown-menu {
    @include dropdown;

    &::before {
        @include dropdown-arrow;
    }
}

// Override BS styles
.dropdown-item {
    @include dropdown-item;

    padding: 1rem;

    &:last-child {
        border-bottom: 0;
    }
}

.navbar {
    background-color: $white;

    .nav-item {
        .nav-link {
            color: $mulled-wine;
            text-transform: uppercase;
            transition: all .3s;

            &:hover {
                color: $dove-gray;
                transform: translateY(-2px);
            }
        }

        &--with-icon {
            a,
            svg {
                opacity: .5;
                transition: all .3s;
            }

            a {
                color: $curious-blue;
            }

            &.active {
                a,
                svg {
                    opacity: 1;
                }
            }

            &:hover {
                a,
                svg {
                    opacity: 1;
                }

                a {
                    color: $curious-blue;
                }
            }
        }
        .btn-outline--header {
            @include media-breakpoint-down(lg) {
                width: 100%;
                text-align: left;
                border-radius: 0;
                box-shadow: none;
            }
        }
    }

    .collapse {
        &::before {
            @include dropdown-arrow;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    &__user {
        background-color: $alabaster;
        border-radius: $border-radius;
        color: $dove-gray;
        font-family: $roboto-medium;
        font-size: 1.2rem;
        padding: 1rem 3rem;
        @include media-breakpoint-down(md) {
            padding: 0;
            text-transform: uppercase;
            font-size: 1.4rem;
            .name,
            .logout {
                text-align: left;
                color: $mulled-wine;
                padding: 1rem;
            }
            .name {
                border-bottom: 1px solid $gallery;
            }
        }
        svg {
            width: 15px;
            height: 15px;
            vertical-align: middle;
            fill: $dove-gray;
            margin-right: 10px;
        }
        a {
            color: $dove-gray;
        }
    }

    @include media-breakpoint-down(lg) {
        .collapse,
        .collapsing {
            @include dropdown;
            position: absolute;
            right: 10px;
            z-index: 5;
        }

        .nav-item {
            @include dropdown-item;

            &--with-icon {
                font-size: $font-size-md;
                text-align: left;

                a {
                    color: $mulled-wine;
                    display: block;
                }

                svg {
                    display: none;
                }
            }

            a {
                padding: 1rem;
            }
        }

    }

    @include media-breakpoint-down(md) {
        &__user {
            background-color: transparent;
        }
    }

    .hamburger {
        outline: none;
        text-transform: uppercase;
    }
}

.nav-item {
    color: $mulled-wine;
    font-family: $roboto-medium;
    font-size: 1.4rem;
    padding: 0 2rem;

    &--with-icon {
        font-size: $font-size-sm;
        text-align: center;

        svg {
            display: block;
            height: 50px;
            margin: auto;
            width: 50px;
        }

        a {
            text-decoration: none;
            text-transform: uppercase;
        }
    }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    background-color: $curious-blue;
    width: 30px;
}
