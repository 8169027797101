.checkmark {
  display: inline-block;
  height: 66px;
  width: 66px;
  transform: rotate(45deg);
}

.checkmark_circle {
  background-color: $lima;
  border-radius: 33px;
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 66px;
}

.checkmark_stem {
  background-color: $white;
  height: 27px;
  left: 33px;
  position: absolute;
  top: 18px;
  width: 6px;
}

.checkmark_kick {
  background-color: $white;
  height: 6px;
  left: 25px;
  position: absolute;
  top: 39px;
  width: 9px;
}
