.modal {
    &-content {
        padding: 3rem 5rem;
    }
    &-header {
        @extend .text-center;
        border-bottom: none;
    }
    &-title {
        text-transform: uppercase;
        width: 100%;
    }
    &-body {
        color: $dove-gray;
    }
    &-footer {
        border-top: none;
    }
}

#survey-create .modal-content {
    overflow:hidden;
}
