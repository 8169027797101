.survey-question {
    background-color: $gallery;
    .progress {
        width: 85px;
        height: 85px;
        .circle {
            font-size: $font-size-md;
            color: $tundora;
            letter-spacing: .2rem;
            strong {
                font-size: $font-size-xl;
                transform: translateY(-2px);
            }
        }
    }
    .question {
        color: $tundora;
        &--think {
            padding: 4rem 0 2rem 0;
            background-color: $polar;
            position: relative;
            > .container {
                @include media-breakpoint-down(lg) {
                    max-width: 100%;
                }
            }
        }
        &__title {
            text-transform: uppercase;
            font-size: $font-size-xl;
            color: $dove-gray;
        }
        &__answers {
            color: $tundora;
            &__item {
                width: 135px;
                height: 135px;
                flex-shrink: 0;
                flex-grow: 0;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                background-size: 145px;
                background-position: -6px 2px;
                background-repeat: no-repeat;
                &__img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: 80px;
                    background-position: center;
                }
                &__active {
                    position: absolute;
                    top: 8%;
                    left: 7%;
                    width: 86%;
                    height: 86%;
                    border-radius: 50%;
                    background-image: linear-gradient(to bottom, #2fc7ec, #1596d4);
                    opacity: 0;
                    transition: all .2s;
                }
            }
            &__label {
                text-transform: uppercase;
                font-size: $font-size-md;
                color: $dove-gray;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 125%;
            }
        }
        &--do {
            padding: 2rem 0 4rem 0;
            .question__answers {
                &__item {
                    width: 35px;
                    height: 35px;
                    background-size: 33px;
                    overflow: auto;
                    background-position: 0 2px;
                    &.first {
                        background-position-x: 1px;
                    }
                    &.last {
                        background-position-x: -1px;
                    }
                    &__active {
                        top: 6px;
                        left: 5px;
                        width: 23px;
                        height: 23px;
                    }
                }
                &__spacer {
                    height: 4px;
                    margin-top: -3px;
                    background-size: 6px;
                }
                &__label {
                    width: unset;
                    &--mobile {
                        text-transform: uppercase;
                        font-size: $font-size-md;
                        color: $dove-gray;
                        min-height: 2.2rem;
                        span {
                            display: none;
                        }
                    }
                }
            }
            .question__title {
                text-align: center;
            }
        }
        &-outro {
            height: 50px;
            background-image: url('../images/survey_do_shadow.svg');
            background-size: 105%;
            background-position: top;
            background-repeat: no-repeat;
            position: relative;
            z-index: -10;
            top: -1px;
            @include media-breakpoint-up(sm) {
                height: 75px;
            }
            @include media-breakpoint-up(md) {
                height: 100px;
            }
            @include media-breakpoint-up(lg) {
                height: 125px;
            }
            @include media-breakpoint-up(xl) {
                height: 150px;
            }
        }
        &__end {
            &__dont-anwser {
                font-size: $font-size-md;
            }
            .btn {
                color: $white;
                font-size: $font-size-md;
                border: none;
                &:hover {
                    color: $white;
                }
                &.disabled {
                    background-color: $alto;
                }
            }
        }
        &__range {
            display: none;
            &__circle {
                position: relative;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: $white;
                overflow: hidden;
                margin: 0 auto;
            }
            &__img {
                position: absolute;
                top: 10px;
                left: 10px;
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                background-repeat: no-repeat;
                background-size: 80px;
                background-position: center;
            }
            &__active {
                position: absolute;
                top: 10px;
                left: 10px;
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                border-radius: 50%;
                background-image: linear-gradient(to bottom, #2fc7ec, #1596d4);
            }
            &__label {
                text-transform: uppercase;
                color: $dove-gray;
                font-size: $font-size-base;
            }
        }

        &--type-2 {
            .question {
                > .container {
                    @include media-breakpoint-down(lg) {
                        max-width: 100%;
                    }
                }
                &__answers {
                    &__item {
                        padding: 10px;
                        background: $white;
                        width: 200px;
                        height: 200px;
                        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .5);
                        cursor: initial;
                        @include media-breakpoint-down(lg) {
                            width: 150px;
                            height: 150px;
                        }
                        &__img {
                            position: initial;
                            background-size: cover;
                            border-radius: 50%;
                        }
                    }
                    &__label {
                        width: 125%;
                        transform: translateX(-53%);
                    }
                    input[type=radio] {
                        background-color: white;
                        border-radius: 50%;
                    }

                }
                &__range {
                    &__circle {
                        padding: 10px;
                    }
                    &__img {
                        position: initial;
                        background-size: cover;
                        border-radius: 50%;
                        background-position: center;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.likert-choices-container {
    white-space: nowrap;
    .likert-choice-container {
        width: 25%;
        display: inline-block;
        position: relative;
        margin: 0 -4px;
        &:first-child {
            .choice-segment {
                width: 50%;
                left: unset;
                right: 0;
            }
            .choice-target-content {
                &:before {
                    display: none;
                }
            }
        }
        &:last-child {
            .choice-segment {
                width: 50%;
                left: 0;
            }
            .choice-target-content {
                &:after {
                    display: none;
                }
            }
        }
    }
    .likert-choice {
        display: block;
    }
    .choice-segment {
        position: absolute;
        width: 100%;
        height: 24px;
        top: calc(50% - 12px);
        background-color: $white;
        box-shadow: inset -1px 2px 1px 0 rgba(0,0,0,.35);
        z-index: 0;
    }
    .choice-target-container {
        position: relative;
        width: 60%;
        left: 25%;
        z-index: 1;
        .active{
            .choice-target-content{
                background: $curious-blue;
                &:before,
                &:after {
                    background-color: transparent;
                }
            }

        }
        &:hover {
            .choice-target-content{
                background: $curious-blue;
                &:before,
                &:after {
                    background-color: transparent;
                }
            }
        }
    }
    .choice-target {
        position: relative;
        height: 0;
        padding-bottom: 100%;
        &.active,
        &:hover {
            .choice-target-content-active {
                opacity: 1;
            }
        }
    }

    .choice-target-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        box-shadow: inset 0 2px 1px 0 rgba(0,0,0,.35);
        border-radius: 50%;
        &:before,
        &:after {
            position: absolute;
            display: block;
            content: '';
            width: 5px;
            height: 22px;
            background-color: $white;
            z-index: 4;
            top: calc(50% - 10px);
            left: -2px;
        }
        &:after {
            left: calc(100% - 4px);
        }
        &:hover {
            &:before,
            &:after {
                background-color: transparent;
            }
        }
        &-active {
            position: absolute;
            top: 5%;
            left: 5%;
            height: 90%;
            width: 90%;
            display: block;
            background-image: linear-gradient(to bottom, #fff, #fff);
            border-radius: 50%;
            z-index: 7;
            opacity: 1;
            transition: opacity .3s ease-in-out;
        }
        &-img {
            position: absolute;
            top: 20%;
            left: 20%;
            height: 60%;
            width: 60%;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 10;
        }
        &-label {
            position: absolute;
            white-space: normal;
            top: calc(100% + 20px);
            display: block;
            width: 100%;
            text-transform: uppercase;
            font-size: $font-size-md;
            color: $dove-gray;
        }
    }
    &--do {
        .choice-target-container {
            /*width: 30%;
            left: 35%;*/
        }
        .choice-segment {
            height: 16px;
            top: calc(50% - 8px);
            @include media-breakpoint-down(md) {
                height: 10px;
                top: calc(50% - 5px);
            }
        }
        .choice-target-content {
            &:before,
            &:after {
                height: 14px;
                top: calc(50% - 6px);
                @include media-breakpoint-down(md) {
                    height: 8px;
                    top: calc(50% - 3px);
                }
            }
        }

    }
}
