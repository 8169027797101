.graph-bar {

    &.template {
        display: none;
    }

    &__header {
        background-color: $mulled-wine;
        color: $white;
        font-size: $font-size-md;
        height: 6rem;
        text-align: center;
        text-transform: uppercase;
    }

    &__content {
        padding: 2rem 3rem 3rem;
    }

    &__item {
        border: 1px solid $mercury;
    }

    &__gap {
        color: $orange;
        font-size: $font-size-md;
        height: 3rem;
        text-align: center;
        text-transform: uppercase;
    }

    &__bars {
        $height: 18rem;

        counter-reset: barLevel;
        display: flex;
        flex-direction: column-reverse;
        height: $height;
        position: relative;

        &__level {
            border-top: 1px dashed rgba(0, 0, 0, .12);
            height: ($height / 3);
            position: relative;

            &::after {
                color: $mulled-wine;
                counter-increment: barLevel;
                content: counter(barLevel);
                font-size: 1.2rem;
                left: -14px;
                position: absolute;
                top: -12px;
            }

            &:first-child {
                border-bottom: 1px dashed rgba(0, 0, 0, .12);

                &::before {
                    bottom: -12px;
                    color: $mulled-wine;
                    content: '0';
                    font-size: 1.2rem;
                    left: -14px;
                    position: absolute;
                }
            }
        }

        &__bar {
            border-radius: 20px;
            border-style: dashed;
            border-width: 1px;
            height: $height;
            overflow: visible;
            position: absolute;
            top: 0;
            width: ($height / 6);

            &--do {
                border-color: $orange;
                left: 20px;

                @include media-breakpoint-down(md) {
                    left: calc(20px + 25%);
                }

                .progress-bar {
                    background-color: $orange;
                }
            }

            &--think {
                border-color: $yellow;
                right: 0;

                @include media-breakpoint-down(md) {
                    right: 25%;
                }

                .progress-bar {
                    background-color: $yellow;
                }
            }

            .progress-bar {
                border: none;
                border-radius: 20px;
                bottom: -1px;
                height: 0;
                left: -1px;
                top: auto;
                transition: all .3s;
                width: calc(100% + 2px);
            }
        }
    }

}
