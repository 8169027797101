.form-control {
  font-size: $font-size-md;
}

label {
  color: $mulled-wine;
  font-size: $font-size-md;
}

input {
  &::placeholder {
    opacity: .7;
  }
}

.password-toggle {
    svg {
        height: 1em;
        vertical-align: middle;
        width: 1em;
    }
}

.form-check-label {
  margin-left: 1rem;
}

// Customize CHECKBOX & RADIO
input[type="checkbox"],
input[type="radio"]{
    position: relative;
    appearance: none;
    box-sizing: content-box;
    overflow: hidden;
    outline: none;
    vertical-align: top;
    transform: translateY(3px);
    // circle
    &:before {
        content: '';
        display: block;
        box-sizing: content-box;
        width: 16px;
        height: 16px;
        border: 1px solid $dove-gray;
        transition: .2s border-color ease;
    }
    &:checked:before {
        border-color: $dove-gray;
        transition: .5s border-color ease;
    }
    &:disabled:before {
        border-color: $dove-gray;
        background-color: $dove-gray;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        box-sizing: content-box;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        background-color: $curious-blue;
        width: 16px;
        height: 16px;
        border-radius: 100vh;
        transform: translate(-50%, -50%) scale(0);
    }
    &[type="radio"] {
        &:before {
            border-radius: 100vh;
        }
        &:after {
            width: 16px;
            height: 16px;
            border-radius: 100vh;
            transform: translate(-50%, -50%) scale(0);
        }
        &:checked:after {
            animation: toggleOnRadio .2s ease forwards;
        }
    }
    &[type="checkbox"] {
        &:before {
            border-radius: 3px;
        }
        &:after {
            width: 10px;
            height: 16px;
            border-radius: 0;
            transform: translate(-50%, -85%) scale(0) rotate(45deg);
            background-color: transparent;
            box-shadow: 4px 4px 0 0 $curious-blue;
        }
        &:checked:after {
            animation: toggleOnCheckbox .2s ease forwards;
        }
    }
    &[type="checkbox"].filled  {
        &:before {
            border-radius: 4px;
            transition: 0.2s border-color ease, .2s background-color ease;
        }
        &:checked:not(:disabled):before {
            background-color: $curious-blue;
        }
        &:not(:disabled):after {
            box-shadow: 4px 4px 0 0 white;
        }
    }

    &.sm {
        &:before {
            width: 12px;
            height: 12px;
        }
        &:after {
            width: 12px;
            height: 12px;
        }
        &[type="radio"] {
            &:after {
                width: 12px;
                height: 12px;
            }
        }
        &[type="checkbox"] {

            &:after {
                width: 7.5px;
                height: 12px;
                box-shadow: 3px 3px 0 0 $curious-blue;
            }
        }
        &[type="checkbox"].filled  {
            &:not(:disabled):after {
                box-shadow: 3px 3px 0 0 white;
            }
        }
    }
}
@keyframes toggleOnCheckbox {
    0% { opacity: 0; transform: translate(-50%, -85%) scale(0) rotate(45deg); }
    70% { opacity: 1; transform: translate(-50%, -85%) scale(.9) rotate(45deg); }
    100% { transform: translate(-50%, -85%) scale(0.8) rotate(45deg); }
}
@keyframes toggleOnRadio {
    0% { opacity: 0; transform: translate(-50%, -50%) scale(0); }
    70% { opacity: 1; transform: translate(-50%, -50%) scale(.9); }
    100% { transform: translate(-50%, -50%) scale(0.8); }
}

// INPUT RANGE
input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
    height: 25px;
    border-width: 16px 0;
    border-radius: 20px;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #2fc7ec, #1596d4);
    cursor: pointer;
    margin-top: 3px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #2fc7ec, #1596d4);
    cursor: pointer;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #2fc7ec, #1596d4);
    cursor: pointer;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    background: $white;
    border: 1px solid $alto;
    height: 35px;
    border-radius: 20px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: $white;
}
input[type=range]::-moz-range-track {
    width: 100%;
    cursor: pointer;
    background: $white;
    border: 1px solid $alto;
    height: 35px;
    border-radius: 20px;
}
input[type=range]::-ms-fill-lower {
    background: $white;
    border: 1px solid $alto;
    height: 35px;
    border-radius: 20px;
}
input[type=range]:focus::-ms-fill-lower {
    background: $white;
}
input[type=range]::-ms-fill-upper {
    background: $white;
    border: 1px solid $alto;
    height: 35px;
    border-radius: 20px;
}
input[type=range]:focus::-ms-fill-upper {
    background: $white;
}
input[type='range']::-moz-focus-outer {
    border: 0;
}
.range-track {
    position: absolute;
    top: -15px;
    left: 0;
    height: 70px;
    width: 2px;
    background-color: $alto;
    z-index: 5;
    &:nth-child(1) {
        left: 13px;
    }
    &:nth-child(2) {
        left: 34%;
    }
    &:nth-child(3) {
        left: 66%;
    }
    &:nth-child(4) {
        left: calc(100% - 13px);
    }
    @-moz-document url-prefix() {
        top: -23px;
    }
}

.pwd-strength-checker {

    .pwd-strength {
        height: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #f5f5f5;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
        box-shadow: inset 0 1px 2px rgba(0,0,0,.1);

        .pwd-strength-bar {
            float: left;
            width: 0;
            height: 100%;
            font-size: 12px;
            line-height: 20px;
            color: #fff;
            text-align: center;
            background-color: #f1f1f1;
            -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
            box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
            -webkit-transition: width .6s ease;
            -o-transition: width .6s ease;
            transition: width .6s ease;

            &.pwd-strength-bar-danger {
                background-color: #d9534f;
            }
            &.pwd-strength-bar-warning {
                background-color: #f0ad4e;
            }
            &.pwd-strength-bar-success {
                background-color: #5cb85c;
            }
        }
    }

}

.pwd-error-list {
    padding-left: 10px;
}