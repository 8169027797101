$borderWidth: 7px;
$animationTime: .5s;
$border-color-default: transparent;
$border-color-fill: $golden-tainoi;
$size: 115px;

$borderWidthMini: 3px;
$border-color-default--mini: $wild-sand;
$border-color-fill--mini: $curious-blue;
$sizeMini: 40px;

$borderWidthMd: 5px;
$sizeMd: 72px;

$howManySteps: 100;

.circle {
  @extend .align-items-center;
  @extend .d-flex;
  @extend .justify-content-center;

  background-color: $golden-tainoi;
  border-radius: 50%;
  color : $white;
  font-size: $font-size-xxl;
  font-weight: normal;
  height: $size;
  width: $size;
}

.progress {
  width: $size;
  height: $size;
  line-height: $size;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }

  > span {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 1;
  }

  .progress-left {
    left: 0;
  }

  .progress-bar {
    background: none;
    border-color: $border-color-fill;
    border-style: solid;
    border-width: $borderWidth;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .progress-left .progress-bar {
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    border-top-right-radius: ($size/2);
    left: 100%;
    transform-origin: center left;
  }

  .progress-right {
    right: 0;

    .progress-bar {
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      border-top-left-radius: ($size/2);
      left: -100%;
      transform-origin: center right;
    }
  }

  .progress-value {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    line-height: 20px;
    text-align: center;

    .circle {
      height: 100%;
      width: 100%;
    }
  }

  &--table {
    height: $sizeMini;
    line-height: $sizeMini;
    margin: inherit;
    width: $sizeMini;

    &:after {
      border: $borderWidthMini solid $border-color-default--mini;
    }

    .progress-bar {
      border-color: $border-color-fill--mini;
      border-width: $borderWidthMini;
    }

    .progress-left .progress-bar {
      border-bottom-right-radius: ($sizeMini/2);
      border-top-right-radius: ($sizeMini/2);
    }

    .progress-right {

      .progress-bar {
        border-bottom-left-radius: ($sizeMini/2);
        border-top-left-radius: ($sizeMini/2);
      }
    }

    .circle {
      background-color: transparent;
      color: $dove-gray;
      font-size: 1.2rem;
    }

    @include media-breakpoint-down(md) {
      height: $sizeMd;
      line-height: $sizeMd;
      width: $sizeMd;

      &:after {
        border-width: $borderWidthMd;
      }

      .progress-bar {
        border-width: $borderWidthMd;
      }

      .progress-left .progress-bar {
        border-bottom-right-radius: ($sizeMd/2);
        border-top-right-radius: ($sizeMd/2);
      }

      .progress-right {

        .progress-bar {
          border-bottom-left-radius: ($sizeMd/2);
          border-top-left-radius: ($sizeMd/2);
        }
      }

      .circle {
        font-size: $font-size-lg;
      }
    }
  }
}

@for $i from 1 through $howManySteps {
  $stepName: ($i*(100 / $howManySteps));

  @if $i <= ($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }
      .progress-left .progress-bar {animation: 0;}
    }
  }

  @if $i > ($howManySteps/2)  {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{($howManySteps/2)} $animationTime linear forwards;
      }
      .progress-left .progress-bar {
        animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
      }
    }
  }
}

@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/($howManySteps/2));
  $degrees: ($degrees*$i);
  @keyframes loading-#{$i}{
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(#{$degrees}deg);
    }
  }
}