/***** MIXINS *****/
@mixin btn--bordered($color: $mulled-wine) {
  @extend .btn;

  background: transparent;
  border-color: $color;
  border-radius: 1rem;
  color: $color;
  text-transform: uppercase;
  &:hover {
    color: $color;
  }
}


.btn {
  font-family: $roboto-medium;
  font-size: $font-size-md;
  text-transform: uppercase;
  transition: all .3s;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
  }

  &-outline {
    &-pedagogical {
      border-color: $golden-tainoi;

      &::before {
          content: url('../images/icon--education.svg');
          display: inline-block;
          height: 34px;
          left: 2rem;
          margin-top: calc(-37px / 2);
          position: absolute;
          top: 24.5px;
          width: 35px;
      }
      &:hover {
          color: $dove-gray;
      }
    }
    &-school {
      border-color: $violet-red;

      &::before {
        content: url('../images/icon--school.svg');
        display: inline-block;
        height: 29px;
        left: 2rem;
        margin-top: calc(-33px / 2);
        position: absolute;
        top: 24.5px;
        width: 27px;
      }

      &:hover {
        color: $dove-gray;
        transform: translateY(-2px);
      }
    }

    &--header {
      color: $dove-gray;
      font-family: $roboto-bold;
      font-size: 1.4rem;
      padding-left: 8rem;
      padding-right: 3rem;
      position: relative;
      text-transform: uppercase;

      @include media-breakpoint-down(lg) {
        border-width: 0;
        color: $mulled-wine;
        font-family: $roboto-medium;
        &::before {
          display: none;
        }
      }
    }
    &--primary {
      @include btn--bordered($primary);
    }
    &--secondary {
      @include btn--bordered;
    }
    &--danger {
      @include btn--bordered($punch);
    }
  }
  &-workflow-draft_to_active,
  &-workflow-archive_to_active {
    background-color: $lima;
    border: none;
    &:hover {
      background-color: darken($lima, 10%);
    }
  }
}
