.home {
    background-color: $white;

    h1 {
        color: $white;
        font-family: $roboto-bold;
        font-size: $font-size-xxxl;
    }

    .footer {
        margin-top: 0;
    }
}
.home-intro {
    position: relative;
    min-height: 500px;
    background-image: linear-gradient(rgba(0, 0, 0, .5),rgba(0, 0, 0, .5)),
        url('../images/bg-home-intro.jpg');
    background-size: cover;
    background-position: center;
    &__content {
        padding: 150px 25px 50px 25px;
        width: 100%;
    }
    &__text {
        color: $white;
        font-size: $font-size-xxl;
        font-weight: 500;
        width: 60%;
        line-height: normal;
        strong {
            font-size: 120%;
            font-weight: 700;
            display: block;
            line-height: 1.5;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            font-size: $font-size-lg;
            strong {
                font-size: $font-size-xxl;
            }
        }
    }
}
.home-steps {
    .carousel {
        &-indicators {
            position: initial;
            li {
                width: 10px;
                height: 10px;
                border: solid 1px $curious-blue;
                border-radius: 50%;
                &.active {
                    background-color: $curious-blue;
                }
            }
        }
        &-item {
            text-align: center;
        }
    }
    &__container {
        margin-top: -70px;
    }
    &__illu {
        width: 200px;
        height: 200px;
        background-color: $curious-blue;
        border-radius: 50%;
        padding: 40px;
        margin: 0 auto;
        svg {
            width: 120px;
            height: 120px;
            fill: $white;
        }
        @include media-breakpoint-down(md) {
            width: 150px;
            height: 150px;
            padding: 33px;
            svg {
                width: 85px;
                height: 85px;
            }
        }
        @include media-breakpoint-down(sm) {
            width: 200px;
            height: 200px;
            svg {
                width: 120px;
                height: 120px;
            }
        }
    }
    span {
        color: $white;
        background-color: $golden-tainoi;
        font-size: 4.8rem;
        padding: 5px;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: inline-block;
        margin: 0 auto;
    }
    h2 {
        font-size: $font-size-base;
        font-weight: 600;
        text-transform: uppercase;
        color: $mulled-wine;
        padding: 0 20px;
        min-height: 55px;
        @include media-breakpoint-up(sm) {
            font-size: $font-size-base;
        }
        @include media-breakpoint-down(lg) {
            min-height: 75px;
        }
        @include media-breakpoint-down(md) {
            min-height: 115px;
        }
        @include media-breakpoint-down(sm) {
            min-height: 0;
            font-size: $font-size-lg;
        }
    }
    p {
        font-size: $font-size-base;
        color: $dove-gray;
        @include media-breakpoint-up(sm) {
            font-size: $font-size-md;
        }
    }
}
.home-sponsor {
    $section-sponsor-height: 580px;
    min-height: $section-sponsor-height;
    position: relative;
    background-image: linear-gradient(rgba(255, 206, 82, .75),rgba(255, 206, 82, .75)),
        url('../images/bg-home-sponsor.jpg');
    background-size: cover;
    background-position: center;
    overflow: hidden;
    &__illu {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: $white;
        margin: 0 auto;
        svg {
            fill: $mulled-wine;
            width: 100px;
            height: 100px;
        }
        @include media-breakpoint-down(sm) {
            background-color: transparent;
        }
    }
    &__title {
        color: $mulled-wine;
        h2 {
            box-shadow: 0 0 0 3px $white;
            display: inline;
            line-height: 2;
            background-color: $white;
            font-size: $font-size-xxl;
            text-transform: uppercase;
            @include media-breakpoint-down(sm) {
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
    &__text {
        background-color: $white;
        color: $mulled-wine;
        font-size: $font-size-lg;
        min-height: calc(#{$section-sponsor-height} - 185px);
        padding: 80px 3rem 3rem 3rem;
        p {
            margin-bottom: 5rem;
        }
        @include media-breakpoint-down(sm) {
            padding: 3rem;
            margin: 0 30px 0 30px;
            min-height: auto;
            p {
                margin-bottom: 0;
            }
        }
        &+.btn {
            color: $white;
            border-color: $white;
            font-size: 1.8rem;
            &:hover {
                color: $white;
            }
        }
    }
}

.articles-section--home__news {
    .articles-section__header {
        background-color: $violet-red;
    }
}

.articles-section--home__folders {
    .articles-section__header {
        background-color: $mulled-wine;
    }
    .articles-section__list__folders {
        top: -5rem;
    }
    .articles-section__category {
        left: 0;
    }
}
