/* ==|====================
   Layouts/Footer
   ======================= */
.institutional-content .footer {
    margin-top: 0;
}
.footer {
    background-color: $curious-blue;
    margin-top: 5rem;
    padding: 4rem 0;

    ul {
        padding-right: 0;
    }

    hr {
        background-color: $white;
        border: none;
        display: none;
        height: 1px;
        margin: auto;
        width: 50%;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    &__column {
        @extend .col-12;
        @extend .col-md-6;
        @extend .col-lg-3;

        border-right: 1px solid $white;

        &:last-child {
            border-right: none;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 3rem;

            &--right {
                border-right: none;
            }
        }

        @include media-breakpoint-down(sm) {
            border-right: none;
            margin-bottom: 0;
            overflow: visible;
            padding-bottom: 3rem;
            padding-top: 3rem;
            text-align: center;
        }
    }

    .nav-link {
        color: $white;
        text-transform: uppercase;
    }

    .footer__logo {
        fill: $white;
        height: 76px;
        width: 195px;
    }

    .facebook {
        fill: $white;
        height: 48px;
        width: 48px;
    }
}
