.survey-conclusion {
    .bg-polar {
        background-color: $polar;
        padding: 5rem 0 2rem 0;
        @include media-breakpoint-down(sm) {
            padding-top: 2rem;
        }
    }
    h1 {
        text-transform: uppercase;
        font-size: 2.4rem;
        @include media-breakpoint-down(sm) {
            text-align: center;
            padding-top: 2rem;
            margin-bottom: 2rem;
        }
    }
    &__header {
        border: 1px solid $mercury;
        border-radius: 4px;
        padding: 2rem;
        background-color: $white;
        &__info {
            color: $mulled-wine;
        }
        @include media-breakpoint-down(sm) {
            border: 0;
            padding: 0;
            background-color: transparent;
            &__info {
                margin-left: 0;
                margin-right: 0;
                .col {
                    border: 1px solid $mercury;
                    border-radius: 4px;
                    padding: 2rem;
                    background-color: $white;
                }
            }
            &__action {
                text-align: center;
                padding-top: 2rem;
            }
        }

    }

    &__results {
        &__title {
            border-bottom: 2px solid $mercury;
            display: inline-block;
            margin-bottom: 3rem;
            margin-top: 3rem;
            padding-bottom: 1rem;
            text-transform: uppercase;
        }
    }
}
